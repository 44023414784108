// SASS
import '../sass/main-online.scss';
import '../sass/mil-chat-box.scss';
require.resolve('underscore/underscore-min.js');
require.resolve('dropzone/dist/min/dropzone.min.js');
require('./libs/misc/iedetector');
require('./libs/misc/mil-reactions');
require('./libs/misc/mil-av-live');
//require('./libs/misc/mil-av-survey')
require('./libs/misc/pmi-survey');
//require('./partials/player')
//require('./partials/live')
require('./partials/select2-below');
require('./partials/engine-online');