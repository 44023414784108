import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.timers.js";
import "core-js/modules/es.parse-int.js";
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window, document) {
  var $survey, $polls;
  var surveyStatus = false;
  var asideResizer = function asideResizer() {
    var AVLive = window.AVLive;
    console.log('<<<<<<<<< AVLive', AVLive);
    if (AVLive) AVLive.chatResize();
  };
  var initSurvey = function initSurvey() {
    $survey = $('#survey');
    if ($survey.length == 0) return false;
    $polls = $('#survey .poll-slide');
    $polls.on('click', '.btn-poll', movePoll);
    $polls.on('click', '.poll-single-value .poll-option', selectSingleValue);
    $polls.on('click', '.poll-single-option .poll-option', selectSingleOption);
    $polls.on('click', '.poll-multiple-options .poll-option', selectOption);
    return true;
  };
  var movePoll = function movePoll(e) {
    var $btn = $(this);
    var $poll = $btn.parents('.poll');
    var pos = $poll.data('slide');
    var dir = $btn.hasClass('btn-prev') ? -1 : 1;
    var toSlide = pos + dir;
    if (toSlide > 0 && toSlide < $polls.length + 1) {
      $survey.find('.poll.active').removeClass('active');
      $survey.find('.poll[data-slide="' + toSlide + '"]').addClass('active');
    } else if (toSlide >= $polls.length) {
      if (!checkFreeField($poll)) {
        return false;
      }
      $survey.find('.poll.active').removeClass('active');
      $survey.find('.poll-sending').addClass('active');
      var results = getPollResults();

      //console.log('result', $results);

      sendPoll(results);
    } else {

      //console.log('donothing!!!!')
    }
    asideResizer();
  };
  var sendPoll = function sendPoll(results) {
    $.ajax({
      type: "POST",
      async: true,
      url: window.survey_url,
      cache: false,
      data: {
        value: JSON.stringify(results)
      }
    }).done(function () {
      setTimeout(function () {
        $survey.find('.poll-sending').removeClass('active');
        $survey.find('.poll-outro').addClass('active');
      }, 1500);
    }).fail(function (response) {
      console.error(response);
    });
  };
  var checkFreeField = function checkFreeField($poll) {
    var $freeField = $poll.find('.free-option-value');
    var isFreeOptionSelected = $poll.find('.poll-option.active[data-free="1"]').length;
    if ($freeField.length && isFreeOptionSelected) {
      var isFilled = $freeField.val();
      if (isFilled.length < 2) {
        alert('Please fill in the field');
        return false;
      }
    }
    return true;
  };
  var selectSingleValue = function selectSingleValue(e) {
    var $btn = $(e.target).hasClass('poll-option') ? $(e.target) : $(e.target).parents('.poll-option');
    if ($btn.hasClass('active')) return;
    var fill = true;
    var value = parseInt($btn.data('value'));
    var $poll = $btn.parents('.poll');
    var $options = $btn.parents('.poll-options');
    $options.find('.active').removeClass('active');
    $btn.addClass('active');
    $options.find('.poll-option').each(function () {
      var $op = $(this);
      var val = parseInt($op.data('value'));
      if (val < value) {

        //$op.addClass('fill')
      } else {
        $op.removeClass('fill');
      }
    });

    //console.log('option selected', value)

    updateNextButton($poll);
  };
  var selectSingleOption = function selectSingleOption(e) {
    var $btn = $(e.target).hasClass('poll-option') ? $(e.target) : $(e.target).parents('.poll-option');
    if ($btn.hasClass('active')) return;
    var $poll = $btn.parents('.poll');
    var $options = $btn.parents('.poll-options');
    $options.find('.active').removeClass('active');
    $btn.addClass('active');
    updateNextButton($poll);
  };
  var selectOption = function selectOption(e) {
    var $btn = $(e.target).hasClass('poll-option') ? $(e.target) : $(e.target).parents('.poll-option');
    var $poll = $btn.parents('.poll');
    if ($btn.hasClass('active')) {
      // Se input vale solo come selezione
      if ($(e.target).hasClass('free-option-value')) {
        return;
      }
      $btn.removeClass('active');
      updateNextButton($poll);
      return false;
    }
    var $options = $btn.parents('.poll-options');
    var selected = $options.find('.active').length;
    var max = $options.data('max');
    if (selected >= max) {
      alert('Please choose maximum ' + max + (max === 1 ? ' answer' : ' answers'));
      return false;
    }
    $btn.addClass('active');
    updateNextButton($poll);
  };
  var updateNextButton = function updateNextButton($poll) {
    if ($poll.find('.poll-option.active').length) {
      /// controllo campo free
      $poll.find('.btn-next').removeAttr('disabled');
    } else {
      $poll.find('.btn-next').attr('disabled', 'disabled');
    }
  };
  var getPollResults = function getPollResults() {
    var results = [];
    $polls.each(function () {
      var $poll = $(this);
      var $pollBody = $poll.find('.poll-body');
      var value = '***ERROR***';
      if ($pollBody.hasClass('poll-single-value') || $pollBody.hasClass('poll-single-option')) {
        value = $pollBody.find('.poll-option.active').data('value');
      } else if ($pollBody.hasClass('poll-multiple-options')) {
        var $selected = $pollBody.find('.poll-option.active');
        value = [];
        $selected.each(function () {
          var $op = $(this);
          var val = $op.data('value');
          if ($op.data('free')) {
            val += ' ' + $op.find('.free-option-value').val();
          }
          value.push(val);
        });
      }
      results.push(value);
    });
    return results;
  };
  var showSurvey = function showSurvey() {
    $('#chat-container').remove();
    asideResizer();
    $survey.removeClass('d-none');
  };

  // window.milc_conf.change_iframe_callback = function () {
  //
  //     if (!surveyStatus) {
  //
  //         surveyStatus = initSurvey();
  //
  //     }
  //
  //     // visualizza survey
  //     showSurvey()
  //
  // }

  window.initSurvey = initSurvey;
  window.showSurvey = showSurvey;
  $(function () {
    surveyStatus = initSurvey();
  });
});