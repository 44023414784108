import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.string.replace.js";
import * as _ from 'lodash';
import FormValidator from '../libs/misc/form-validator';
import AVLive from '../libs/misc/mil-av-live';
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window, document) {
  // shim layer with setTimeout fallback
  window.requestAnimationFrame = function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };
  }();
  var getViewport = function getViewport() {
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;
    return {
      x: x,
      y: y
    };
  };
  var password_options_en = {
    enterPass: 'Type your password.',
    shortPass: 'Password too short, 6 char at least.',
    containsField: 'The password contains your username',
    badPass: 'Weak; try combining letters & numbers',
    goodPass: 'Medium; try using special characters',
    strongPass: 'Strong password',
    steps: {
      15: 'Really insecure password',
      35: 'Weak; try combining letters & numbers',
      70: 'Medium; try using special characters',
      90: 'Strong password'
    },
    showPercent: false,
    showText: true,
    animate: true,
    minimumLength: 6
  };
  var password_options_it = {
    enterPass: 'Scegli la tua password',
    shortPass: 'La password è troppo corta, minimo 6 caratteri',
    containsField: 'La password contiene il tuo username',
    badPass: 'Password facile, prova a combinare lettere e numeri',
    goodPass: 'Password media, prova ad usare anche caratteri speciali',
    strongPass: 'Password sicura',
    steps: {
      15: 'Password non sicura',
      35: 'Password facile, prova a combinare lettere e numeri',
      70: 'Password media, prova ad usare anche caratteri speciali',
      90: 'Password sicura'
    },
    showPercent: false,
    showText: true,
    animate: true,
    minimumLength: 6
  };
  var password_options_mix = {
    enterPass: 'Scegli la tua password / Type your password',
    shortPass: 'La password è troppo corta, minimo 6 caratteri / Password too short, 6 char at least',
    containsField: 'La password contiene il tuo username / The password contains your username',
    badPass: 'Password facile, prova a combinare lettere e numeri / Weak; try combining letters & numbers',
    goodPass: 'Password media, prova ad usare anche caratteri speciali / Medium; try using special characters',
    strongPass: 'Password sicura / Strong password',
    steps: {
      15: 'Password non sicura / Really insecure password',
      35: 'Password facile, prova a combinare lettere e numeri / Weak; try combining letters & numbers',
      70: 'Password media, prova ad usare anche caratteri speciali / Medium; try using special characters',
      90: 'Password sicura / Strong password'
    },
    showPercent: false,
    showText: true,
    animate: true,
    minimumLength: 6
  };
  function resizePage() {
    var vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }
  function init_allinone_page() {
    var $view = $('#view.live-view');
    if (!$view.length) return;
    var scrollTo = 0,
      isScrolling = false;
    var removeSnap = function removeSnap() {
      if (!isSafari) {
        $view.removeClass('snap-view');
      }
    };
    var addSnap = function addSnap() {
      if (!isSafari) {
        $view.addClass('snap-view');
      }
    };
    var iOS = function iOS() {
      return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      navigator.userAgent.includes('Mac') && 'ontouchend' in document;
    };
    var isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    }(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification);
    console.log('isSafari', isSafari);
    isSafari = isSafari || iOS();
    console.log('isSafari2', isSafari);
    if (isSafari) {
      $('body').addClass('safari');
      $view.addClass('snap-view-safari');
      //.removeClass('view-snap')
    }

    // prevent snap when opening biographies
    $('.read-more').on('click', function (e) {
      e.preventDefault();
      var btn = $(this);
      var target = $(btn.data('target'));

      //target.addClass( 'collapsing' )
      isScrolling = true;
      removeSnap();
      if (btn.hasClass('collapsed')) {
        target.slideDown(function () {
          btn.removeClass('collapsed');
          addSnap();
        });
      } else {
        target.slideUp(function () {
          btn.addClass('collapsed');
          addSnap();
        });
      }
      isScrolling = false;
      return false;
    });

    // nav link active
    $('.bar-link').on('click', function () {
      $('.bar-item.active').removeClass('active');
      $(this).parent().addClass('active');
    });

    // scroll to slide
    $('a[data-slide]').on('click', function (e) {
      var slide = $(this).data('slide');
      var target = $("section[data-slide=\"".concat(slide, "\"]"));
      if (target.length) {
        e.preventDefault();
        isScrolling = true;
        // console.log('$top', $top, target.offset().top);

        removeSnap();
        scrollTo = $view.scrollTop() + target.offset().top;
        $view.stop(true).animate({
          scrollTop: scrollTo
        }, 800, function () {
          addSnap();
          isScrolling = false;
        });
        return false;
      }
    });
    var sections = document.querySelectorAll('section.live-section');
    var observer = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting && !isScrolling) {
          var slide = entry.target.dataset.slide;
          var $target = $("#main-nav a[data-slide=\"".concat(slide, "\"]"));
          if ($target.length) {
            $('.bar-item.active').removeClass('active');
            $target.parent().addClass('active');
          }
        }
      });
    }, {
      rootMargin: '-50% 0px'
    });
    sections.forEach(function (section) {
      return observer.observe(section);
    });
  }
  function init_common() {
    $('body').removeClass('preloading');
    resizePage();
    $('[data-toggle="tooltip"]').tooltip();

    // track menu
    $('a[data-track]').click(function (e) {
      var what = $(this).data('track');
      $.ajax({
        method: 'post',
        url: '/clicked',
        data: {
          what: what
        }
      });

      //console.debug('ajaxed', what)
    });
    window.onresize = _.debounce(resizePage, 20);
  }
  function init_register() {
    var registerFormValidator = new FormValidator({
      form: {
        id: '#register-form',
        errors: '.errors',
        btn_submit: '#btn-register',
        recaptcha: '#g-recaptcha-response'
      },
      fields: ['email', 'privacy', 'password', 'password_check', 'track'],
      init_fields: false,
      errors: {
        email: 'Use a valid email',
        password: 'Password requirements not met',
        password_check: "<i>Password</i> and <i>confirm password</i> fields do not match",
        privacy: "Please accept <i>the terms of use for events</i>",
        track: "Please choose regarding your <i>personalized experience</i>",
        recaptcha: 'Click on "I\'M NOT A ROBOT"'
      },
      password_mode: {
        field: '#password'
      },
      remote_errors: [{
        param: 's',
        value: '400',
        message: 'Recaptcha error, please try again'
      }, {
        param: 's',
        value: '406',
        message: 'Password does not match the requirements'
      }, {
        param: 's',
        value: '422',
        message: 'Wrong credentials'
      }]
    });
    registerFormValidator.options.remote_errors.push({
      param: 's',
      value: '409',
      message: "User already registered, go to <a href=\"".concat(registerFormValidator.$form.data('login-url'), "\" target=\"_blank\">log in</a>")
    });
    var is_register = registerFormValidator.initialize();
    console.log('registerFormValidator.remote_errors', registerFormValidator.remote_errors);
  }
  function init_login() {
    var loginFormValidator = new FormValidator({
      form: {
        id: '#login-form',
        errors: '.errors',
        btn_submit: '#btn-login',
        recaptcha: '#g-recaptcha-response'
      },
      fields: ['email'],
      errors: {
        email: 'Use a valid email',
        recaptcha: 'Click on "I\'M NOT A ROBOT"'
      },
      password_mode: false,
      remote_errors: [{
        param: 's',
        value: '422',
        message: 'Wrong credentials'
      }, {
        param: 's',
        value: '400',
        message: 'Recaptcha error, please try again'
      }, {
        param: 's',
        value: '429',
        message: 'Account locked '
      }]
    });
    var is_login = loginFormValidator.initialize();
    //console.log('is_login', is_login)
  }
  function init_forgot() {
    var forgotFormValidator = new FormValidator({
      form: {
        id: '#forgot-form',
        errors: '.errors',
        btn_submit: '#btn-forgot',
        recaptcha: '#g-recaptcha-response'
      },
      fields: ['email'],
      errors: {
        email: 'Use a valid email',
        recaptcha: 'Click on "I\'M NOT A ROBOT"'
      },
      password_mode: false,
      remote_errors: [{
        param: 's',
        value: '422',
        message: 'Wrong credentials'
      }, {
        param: 's',
        value: '400',
        message: 'Recaptcha error, please try again'
      }]
    });
    var is_forgot = forgotFormValidator.initialize();
  }
  function init_change_password() {
    var change_passwordFormValidator = new FormValidator({
      form: {
        id: '#change_password_form',
        errors: '.errors',
        btn_submit: '#change-password',
        recaptcha: '#g-recaptcha-response'
      },
      fields: ['password', 'password_check'],
      errors: {
        password: 'Password requirements not met',
        password_check: "<i>Password</i> and <i>confirm password</i> fields do not match",
        recaptcha: 'Click on "I\'M NOT A ROBOT"'
      },
      password_mode: {
        field: '#password'
      },
      remote_errors: [{
        param: 's',
        value: '400',
        message: 'Recaptcha error, please try again'
      }, {
        param: 's',
        value: '406',
        message: 'Password already used, choose a different one'
      }]
    });
    var is_change_password = change_passwordFormValidator.initialize();
    console.log('is_change_password', is_change_password);
  }
  function init_access() {
    var accessFormValidator = new FormValidator({
      form: {
        id: '#access-form',
        errors: '.access-errors',
        btn_submit: '#btn-access'
        //recaptcha: "#g-recaptcha-response",
      },
      fields: ['name', 'surname', 'email'],
      errors: {
        name: 'Inserisci un nome di almeno 2 caratteri',
        surname: 'Inserisci un cognome valido',
        email: 'Inserisci una email valida'
      },
      password_mode: false,
      remote_errors: []
    });
    var is_access = accessFormValidator.initialize();
  }
  function init_live() {
    if (window.milc_conf && window.milc_conf.av_live) {
      window.AVLive = new AVLive();
    }
    console.debug('-----> AVLive setup ', window.AVLive);
  }
  function init_survey() {
    var poll_box_id = '#poll-box';
    var survey = $(poll_box_id);
    if (survey.length == 0) return;
    console.debug('-----> init survey ', survey);
    var ratings = $('.rating');
    var btn_send = $('#btn-send-survey');
    var poll = $('.poll-1');
    var poll_sending = $('.poll-sending');
    var poll_intro = $('.poll-intro');
    var poll_outro = $('.poll-outro');
    var poll_select;
    // var poll_select = $( '#poll-2-select' ).select2( {
    //     placeholder: "Scegli una risposta",
    //     allowClear: false,
    //     minimumResultsForSearch: -1,
    //     closeOnSelect: false
    // }).on("select2:closing", function(e) {

    //     e.preventDefault();

    // }).on("select2:closed", function(e) {

    //     poll_select.select2("open");

    // })

    //poll_select.select2("open");

    var pollNum = 1;
    window.startPoll = function () {
      poll_intro.hide();
      $('.poll-' + pollNum).removeClass('d-none');
    };
    $('#poll-box .prev-btn').click(function () {
      $('.poll-' + pollNum).addClass('d-none');
      pollNum--;
      $('.poll-' + pollNum).removeClass('d-none');
    });
    $('#poll-box .next-btn').click(function () {
      $('.poll-' + pollNum).addClass('d-none');
      pollNum++;
      if ($('.poll-' + pollNum).length) {
        $('.poll-' + pollNum).removeClass('d-none');
      }
      if (pollNum == 3) {
        console.log('open');
        poll_select = $('#poll-2-select').select2({
          placeholder: 'Scegli una risposta',
          allowClear: false,
          minimumResultsForSearch: -1,
          closeOnSelect: false,
          dropdownPosition: 'below'
        }).on('select2:closing', function (e) {
          e.preventDefault();
        }).on('select2:closed', function (e) {
          poll_select.select2('open');
        }).on('select2:select', function (e) {
          poll_select.parents('.poll').find('button.next-btn').removeAttr('disabled');
        });
        poll_select.select2('open');
      }
    });
    ratings.each(function () {
      var box = $(this);
      $('.star', box).each(function (idx) {
        $(this).click(function () {
          $(box).removeClass(['value-1', 'value-2', 'value-3', 'value-4', 'value-5']).addClass('value-' + (idx + 1));
          $('.label-value', box).text('' + (idx + 1) + ' / 5');
          $(box).parents('.poll').find('button.next-btn').removeAttr('disabled');
        });
      });
    });
    var $last_poll_slide = $('#poll-box .poll-slide').last();
    $last_poll_slide.find('.next-btn').click(function () {
      var value = [];
      $('.rating .label-value').each(function () {
        value.push(parseInt($(this).text().replace(' / 5')));
      });
      var data = {
        value: JSON.stringify(value),
        select: poll_select.select2('data')[0].id
      };
      console.log('risultato survey', data);
      poll_select.select2('destroy');
      poll_sending.removeClass('d-none');
      $.ajax({
        type: 'POST',
        async: true,
        url: window.survey_url,
        cache: false,
        data: data
      }).done(function () {
        setTimeout(function () {
          poll_sending.addClass('d-none');
          poll_outro.removeClass('d-none');
        }, 1500);
      }).fail(function (response) {
        console.error(response);
      });
    });
    //setTimeout( startPoll, 3000 );
  }
  $(function () {
    init_common();
    init_allinone_page();

    // init_register()

    // init_login()

    //init_access();

    // init_forgot()

    // init_change_password()

    init_live();

    //        init_survey();
  });
});